import React from 'react';

import { VictoriaLogsQueryEditorProps } from "../../types";

const QueryEditorForAlerting = (props: VictoriaLogsQueryEditorProps) => {

  return (
    <div>QueryEditorForAlerting</div>
  );
}

export default QueryEditorForAlerting
